@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  color: #32334f;
  font-family: 'Inter';
}
.border-primary {
  border: 1px solid #32334f;
  border-radius: 4px;
}

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* 
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
} */
.header .bg-default {
  background: none;
}

.dropdown {
  position: relative;
}

.dropdown.disabled {
  opacity: 0.5;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}

.dropdown-header.disabled {
  cursor: not-allowed;
  opacity: 0.6; /* Slightly faded when disabled */
}

.arrow {
  font-size: 10px;
}

.arrow.up {
  transform: rotate(180deg); /* Up arrow */
}

.dropdown-list {
  /* position: absolute; */
  top: 100%;
  font-size: 14px;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown.disabled {
  pointer-events: none; /* Prevent any interaction */
}
.dropdown-option.selected {
  background-color: #007bff;
  color: white;
}

.dropdown-list {
  max-height: 200px; /* Limit the dropdown height */
  overflow-y: auto; /* Enable scrolling */
}

.dropdown-option {
  display: flex;
  align-items: center; /* Center the checkbox and text vertically */
  padding: 10px;
  cursor: pointer;
}

.dropdown-option input[type='checkbox'] {
  margin-right: 10px; /* Space between checkbox and label */
}

.center {
  position: absolute;
  z-index: 102;
  left: 50%;
  top: 50%;
  transform: translate(0, -50%);
  width: 120px;
  height: 120px;
  margin: auto;
}

.spinner {
  border: 7px solid #d7d7fa;
  border-radius: 50%;
  border-top: 7px solid #373ee3;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loadingMessage {
  font-weight: bold;
  margin-left: -100%;
  margin-right: -100%;
  margin-top: -60%;
  text-align: center;
}

.message {
  font-weight: bold;
  margin-left: -100%;
  margin-right: -100%;
  margin-top: 70%;
  text-align: center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes jump {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

@media screen and (max-width: 420px) {
  .center {
    position: absolute;
    z-index: 102;
    left: 35%;
    top: 40%;
    transform: translate(0, -50%);
    width: 120px;
    height: 120px;
    margin: auto;
  }
}

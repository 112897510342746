.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.center {
  position: fixed;
  z-index: 102;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
}

.spinner {
  border: 7px solid #d7d7fa;
  border-radius: 50%;
  border-top: 7px solid #373ee3;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.loadingMessage {
  font-weight: bold;
  position: absolute;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
  top: -60px;
  text-align: center;
}

.message {
  font-weight: bold;
  position: absolute;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
  top: 140px;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 420px) {
  .center {
    left: 50%;
    top: 40%;
  }
}

/* Button.css */
.customButton {
  border: none;
  min-width: 150px;
  padding: 10px 20px;
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

/* Primary button */
.customButton.primary {
  background-color: #373ee3;
  color: white;
}

/* Secondary button */
.customButton.secondary {
  background-color: transparent;
  /* Changed from 'none' to 'transparent' */
  color: #32334f;
  border: 1px solid #32334f;
}

/* Success button */
.customButton.success {
  background-color: #28a745;
}

.customButton.success:hover {
  background-color: #218838;
}

/* Danger button */
.customButton.danger {
  background-color: #dc3545;
}

.customButton.danger:hover {
  background-color: #c82333;
}

/* Disabled button */
.customButton:disabled {
  background-color: #ddd;
  color: #aaa;
  /* Added color for better visibility */
  cursor: not-allowed;
  border: none;
}
